function SleepStudyInReview() {
    return (
        <div className="layout-outlet-root max-w-3xl">
            <h2 className="font-heading text-[44px] lg:text-7xl mb-6 flex items-center">
                <span>Your Sleep Study is in Good Hands!</span>
            </h2>
            <p className="mb-4 leading-normal lg:text-xl">
                You have successfully uploaded your Sleep Study. Our GEM SLEEP Providers will
                complete their review within 5-7 business days. Once the review is complete you will
                receive a notification to review your personalized treatment recommendation.
            </p>
            <p className="mb-4 leading-normal lg:text-xl">
                If more information is needed, we will contact you directly via the phone number
                provided.
            </p>
            <p className="mb-8 leading-normal lg:text-xl font-bold">Sleep well!</p>
        </div>
    );
}

export default SleepStudyInReview;

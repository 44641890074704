import { useSelector } from 'react-redux';
import { useGetBlogPostsQuery } from '../../app/services/webflow';
import cpapTreatment from '../../images/cpap-treatment.jpg';
import dentalTreatment from '../../images/dental-treatment.jpg';
import { Navigate } from 'react-router-dom';
//Components
import { PageHeader } from '../../components/LayoutComponents';
import { CareTeamCTA } from '../../components/Treatment';
import { ArticlesSlider } from '../../components';
import { AppointmentReminder } from '../../components/Appointments';

const PreRecommendations = () => {
    const { user } = useSelector((state) => state.auth);
    const { data: blogPosts } = useGetBlogPostsQuery();

    return (
        <>
            {user?.patientProfile?.homeSleepStudyStatus === 'NOT_INITIATED' &&
            user?.patientProfile?.mdStatus === 'UPLOADED_SLEEP_STUDY_INCOMPLETE' ? (
                <Navigate to="/dashboard/diagnosis/get-diagnosed" />
            ) : (
                <>
                    <PageHeader>GEM SLEEP Treatments</PageHeader>
                    <p className="mb-6 max-w-4xl text-sm lg:text-lg">
                        Learn more about the treatment options that may be available through GEM
                        SLEEP. A GEM SLEEP provider will review your sleep study results, and
                        provide a customized treatment recommendation based on your clinical
                        situation and preferences.
                    </p>

                    <AppointmentReminder />

                    <ul className="mb-10 space-y-4 lg:flex lg:space-y-0 lg:space-x-4">
                        <li className="bg-white lg:flex-1">
                            <div className="aspect-w-8 aspect-h-4 bg-gray-six">
                                <img
                                    src={cpapTreatment}
                                    alt=""
                                    className="w-full object-cover object-center"
                                />
                            </div>
                            <div className="bg-white p-4 lg:p-[30px]">
                                <h4 className="mb-3 font-heading text-[28px] leading-none lg:text-[40px]">
                                    CPAP Therapy
                                </h4>
                                <p className="mb-5 text-xs lg:mb-8 lg:text-sm">
                                    Continuous Positive Airway Pressure, or CPAP, is the most common
                                    treatment for sleep apnea.
                                </p>
                                <a
                                    className="btn-primary"
                                    href="https://www.mygemsleep.com/cpap-therapy"
                                    target="_blank"
                                    rel="noreferrer">
                                    Learn more
                                </a>
                            </div>
                        </li>
                        <li className="bg-white lg:flex-1">
                            <div className="aspect-w-8 aspect-h-4 bg-gray-six">
                                <img
                                    src={dentalTreatment}
                                    alt=""
                                    className="w-full object-cover object-center"
                                />
                            </div>
                            <div className="bg-white p-4 lg:p-[30px]">
                                <h4 className="mb-3 font-heading text-[28px] leading-none lg:text-[40px]">
                                    Oral appliance therapy
                                </h4>
                                <p className="mb-5 text-xs lg:mb-8 lg:text-sm">
                                    Oral appliance therapy uses a mouthguard-like appliance to treat
                                    sleep apnea and can be a good alternative for certain people.
                                </p>
                                <a
                                    className="btn-primary"
                                    href="https://www.mygemsleep.com/dental-sleep-medicine"
                                    target="_blank"
                                    rel="noreferrer">
                                    Learn more
                                </a>
                            </div>
                        </li>
                    </ul>

                    <CareTeamCTA />

                    {blogPosts?.items && (
                        <ArticlesSlider
                            className="mb-10"
                            label="Sleep Resources"
                            slidesPerViewDesktop={3}
                            slides={blogPosts.items.filter((i) => i._draft === false)}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default PreRecommendations;

import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../app/services/auth';
import { hasRole } from '../../utils/roleUtils';

const initialState = {
    user: null,
    isLoggedIn: localStorage.getItem('_gh_user_logged_in')
        ? localStorage.getItem('_gh_user_logged_in')
        : false,
    token: null,
    storageToken: null,
    twoFactorRequired: false,
    isPatient: false,
    isCoach: false,
    isMD: false,
};

const slice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        logOut: (state) => {
            state.isLoggedIn = false;
            state.user = null;
            state.token = null;
            state.storageToken = null;
            state.twoFactorRequired = false;
            localStorage.removeItem('_gh_user_logged_in');
        },
        tokenReceived: (state, { payload: { access_token, storage_token } }) => {
            state.token = access_token;
            state.storageToken = storage_token;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(api.endpoints.login.matchFulfilled, (state, { payload }) => {
                const { user, access_token, storage_token, twoFactorRequired } = payload;
                state.token = access_token;
                state.twoFactorRequired = twoFactorRequired;
                if (twoFactorRequired) {
                    return;
                } else {
                    state.user = user;
                    state.isPatient = hasRole(user.userRoles, 'PATIENT');
                    state.isCoach = hasRole(user.userRoles, 'COACH');
                    state.isMD = hasRole(user.userRoles, 'MD');
                    state.storageToken = storage_token;
                    state.isLoggedIn = true;
                    localStorage.setItem('_gh_user_logged_in', true);
                }
            })
            .addMatcher(api.endpoints.verifyCode.matchFulfilled, (state, { payload }) => {
                const { user, access_token, storage_token } = payload;
                state.twoFactorRequired = false;
                state.user = user;
                state.isPatient = hasRole(user.userRoles, 'PATIENT');
                state.isCoach = hasRole(user.userRoles, 'COACH');
                state.isMD = hasRole(user.userRoles, 'MD');
                state.storageToken = storage_token;
                state.isLoggedIn = true;
                state.token = access_token;
                localStorage.setItem('_gh_user_logged_in', true);
            })
            .addMatcher(api.endpoints.getMe.matchFulfilled, (state, { payload: user }) => {
                state.user = user;
                state.isPatient = hasRole(user.userRoles, 'PATIENT');
                state.isMD = hasRole(user.userRoles, 'MD');
            })
            .addMatcher(api.endpoints.updateMe.matchFulfilled, (state, { payload: user }) => {
                if (!state.user) return;

                Object.keys(user).forEach((updateKey) => {
                    if (user[updateKey] !== state.user[updateKey])
                        state.user[updateKey] = user[updateKey];
                });

                Object.keys(user.patientProfile).forEach((updateKey) => {
                    if (user.patientProfile[updateKey] !== state.user.patientProfile[updateKey])
                        state.user.patientProfile[updateKey] = user.patientProfile[updateKey];
                });
            })
            .addMatcher(
                api.endpoints.getMySovasageRecommendation.matchFulfilled,
                (state, { payload }) => {
                    if (payload) state.user.patientProfile.recommendedGemBundleNumber = payload;
                },
            )
            .addMatcher(api.endpoints.getStopBangResponses.matchFulfilled, (state, { payload }) => {
                if (payload) state.user.stopBangResponses = payload;
            })
            .addMatcher(
                api.endpoints.refreshTokens.matchFulfilled,
                (state, { payload: { access_token, storage_token } }) => {
                    state.token = access_token;
                    state.storageToken = storage_token;
                },
            )
            .addMatcher(api.endpoints.logout.matchFulfilled, (state) => {
                state.isLoggedIn = false;
                state.user = null;
                state.token = null;
                state.storageToken = null;
                if (window.zE) window.zE('messenger', 'logoutUser');
                localStorage.removeItem('_gh_user_logged_in');
            })
            .addMatcher(api.endpoints.cancelAppointment.matchFulfilled, (state) => {
                let userAppointments = [...state.user.appointments];
                let lastAppointment = userAppointments.pop();

                lastAppointment.appointmentStatus = 'CANCELLED';

                state.user.appointments = [lastAppointment];
            });
    },
});

export const { logOut, tokenReceived } = slice.actions;

export default slice.reducer;

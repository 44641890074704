import { isWeekend } from 'date-fns';
import { AnimatePresence, motion, useMotionValueEvent, useScroll } from 'framer-motion';
import { useEffect, useState } from 'react';
import { FaExclamation } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MyAccount, Nav } from '..';
import { useGetMeQuery, useLazyGetPaymentUrlQuery } from '../../app/services/auth';
import { useGetCartQuery } from '../../app/services/cart';
import { useGetStateServicesQuery } from '../../app/services/insurance';
import {
    useGetCurrentSuppliesQuery,
    useGetMySovasageRecommendationQuery,
    useGetOrderHistoryQuery,
    useGetSquareProductsQuery,
} from '../../app/services/orders';
import { toggleAccount, toggleCart, toggleModal, toggleNav } from '../../features/ui/uiSlice';
import { ReactComponent as AccountIcon } from '../../images/icons/account.svg';
import { ReactComponent as CartIcon } from '../../images/icons/cart.svg';
import { disableAnalytics } from '../../utils/disableAnalytics';
import useWebWidget from '../../utils/useWebWidget';
import { Breadcrumbs } from '../Breadcrumbs/BreadcrumbsDisplay.js';
import Cart from '../Cart/Cart';
import { CheckoutContextProvider } from '../Checkout/CheckoutContext';
import { InsuranceContextProvider } from '../Insurance';
import { AuthenticatedModals, UnauthenticatedModals } from '../Modals';

const AuthenticatedLayout = () => {
    disableAnalytics();
    const location = useLocation();
    const dispatch = useDispatch();
    const { user, isLoggedIn, isMD } = useSelector((state) => state.auth);
    const { navOpen, cartOpen, accountOpen, orderHistoryOpen, pendingMaskFitReview } = useSelector(
        (state) => state.ui,
    );
    const { catalogItems, recommendedSku } = useSelector((state) => state.catalog);

    //The logic below here determines if scrolling has occurred in order to hide desktop labels for the account and cart buttons
    const { scrollY } = useScroll();
    const [labelsVisible, setLabelsVisible] = useState(true);

    useMotionValueEvent(scrollY, 'change', (latest) => {
        if (latest > 50 && labelsVisible) {
            setLabelsVisible(false);
        } else if (latest < 50 && !labelsVisible) {
            setLabelsVisible(true);
        }
    });

    // This call is made specifically to run the matcher logic
    useGetMySovasageRecommendationQuery(null, {
        skip: recommendedSku || !pendingMaskFitReview,
        pollingInterval: 30000,
    });

    const { isLoading } = useGetMeQuery(undefined, {
        skip: user || !isLoggedIn,
    });

    const { isLoading: catalogLoading } = useGetSquareProductsQuery(null, {
        skip: catalogItems.length > 0,
    });

    useGetOrderHistoryQuery(user?.id, { skip: !user });

    useGetStateServicesQuery(user?.state, { skip: !user });

    useGetCurrentSuppliesQuery();

    const { data: cart = { orderItems: [] } } = useGetCartQuery();

    // payment button handler for temporary url
    const [getPaymentUrl] = useLazyGetPaymentUrlQuery();
    async function handlePaymentClick() {
        const w = window.open('', '_blank');
        getPaymentUrl()
            .then((response) => {
                const paymentUrl = response.data?.url;
                w.location.href = paymentUrl;
            })
            .catch((e) => {
                toast.error('An error has occurred');
            });
    }

    useWebWidget(!isWeekend(new Date()));

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        dispatch(toggleNav(false));
    }, [location, dispatch]);

    useEffect(() => {
        if (user?.hasInvoices) {
            if (location.pathname === '/dashboard') {
                dispatch(toggleModal('payMyBill'));
            }
        }
    }, [dispatch, user?.hasInvoices, location.pathname]);

    return !isLoggedIn ? (
        <Navigate
            to={location.pathname === '/dashboard/diagnosis' ? '/sign-in' : '/'}
            state={{ from: location.pathname }}
        />
    ) : isMD ? (
        <Navigate to="/admin" replace={true} />
    ) : (
        <InsuranceContextProvider>
            <CheckoutContextProvider>
                <div className="lg:flex">
                    <motion.button
                        style={{ x: cartOpen || accountOpen || orderHistoryOpen ? '-100%' : '0%' }}
                        className="fixed top-0 left-0 z-50 h-12 w-12 bg-black p-2 text-purple-light transition lg:hidden"
                        onClick={(ev) => {
                            dispatch(toggleNav());
                        }}>
                        <motion.span
                            className="absolute top-4 block h-px w-8 bg-purple-light transition duration-300 ease-out-quad"
                            style={{
                                y: navOpen ? 6 : 0,
                                opacity: navOpen ? 0 : 1,
                            }}></motion.span>
                        <span className="block h-px w-8 bg-purple-light"></span>
                        <motion.span
                            className="absolute bottom-4 block h-px w-8 bg-purple-light transition duration-300 ease-out-quad"
                            style={{
                                y: navOpen ? -6 : 0,
                                opacity: navOpen ? 0 : 1,
                            }}></motion.span>
                    </motion.button>

                    <Nav />

                    <Cart />

                    {user && (
                        <MyAccount
                            closeHandler={() => {
                                dispatch(toggleAccount(false));
                            }}
                        />
                    )}

                    <nav className="fixed top-2 lg:top-5 right-5 z-10 flex space-x-3">
                        {user?.hasInvoices && (
                            <button
                                onClick={handlePaymentClick}
                                className="bg-[#726AD3] hover:bg-purple text-white hover:text-black font-bold rounded-md p-2 flex items-center px-4 max-w-[200px]">
                                <FaExclamation className="text-red mr-1 -ml-1" /> Pay my Bill
                            </button>
                        )}
                        <button
                            className="flex items-center text-black z-20"
                            onClick={(ev) => {
                                dispatch(toggleAccount(true));
                            }}>
                            <span
                                className={`mr-4 hidden font-heading font-bold tracking-widest 
                    ${labelsVisible ? 'lg:inline-block' : ''}`}>
                                Account
                            </span>
                            <div className="relative h-9 w-9 rounded-full bg-white shadow-md lg:h-12 lg:w-12">
                                <AccountIcon className="absolute-center w-4" />
                            </div>
                        </button>
                        <button
                            className="flex items-center text-black"
                            onClick={() => {
                                dispatch(toggleCart(true));
                            }}>
                            <span
                                className={`mr-4 hidden font-heading font-bold tracking-widest 
                        ${labelsVisible ? 'lg:inline-block' : ''}`}>
                                Cart
                            </span>
                            <div className="relative h-9 w-9 rounded-full bg-white shadow-md lg:h-12 lg:w-12">
                                {cart.orderItems.length > 0 && (
                                    <span className="absolute right-0 top-0 block h-4 w-4 translate-x-1/4 -translate-y-1/4 rounded-full bg-salmon text-center font-heading text-xs font-bold leading-4 text-white transition lg:h-5 lg:w-5 lg:text-sm lg:leading-5">
                                        {cart.orderItems.length}
                                    </span>
                                )}
                                <CartIcon className="absolute-center w-4" />
                            </div>
                        </button>
                    </nav>

                    <div className="relative flex min-h-screen flex-col bg-offwhite lg:w-4/5">
                        <Breadcrumbs />

                        {isLoading || catalogLoading ? (
                            <div className="layout-outlet-root h-screen font-heading text-[44px] font-bold text-gray-six lg:text-7xl">
                                loading...
                            </div>
                        ) : (
                            <div className="mx-auto w-full flex-1">
                                <AnimatePresence mode="wait" initial="false">
                                    <Outlet />
                                </AnimatePresence>
                            </div>
                        )}

                        <div className="bg-black py-10 grid grid-rows gap-4 pl-4 lg:pl-0 lg:text-center text-white">
                            <p className="text-[13px] font-semibold lg:text-base">
                                Have a question? Call us.
                            </p>
                            <h2 className="font-heading text-5xl font-bold text-purple-fair lg:text-6xl">
                                <a href="tel:+18339464436" className="border-none">
                                    1-833-946-4GEM
                                </a>
                            </h2>
                            <p className="text-[13px] text-gray-light lg:text-base">
                                (1-833-946-4436)
                            </p>
                        </div>
                    </div>
                </div>
                <AuthenticatedModals />
                <UnauthenticatedModals />
            </CheckoutContextProvider>
        </InsuranceContextProvider>
    );
};

export default AuthenticatedLayout;

import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../features/ui/uiSlice';
import { useGetMeQuery } from '../../app/services/auth';
import { UploadFile } from '../../components';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as TopRightArrow } from '../../images/top-right-arrow.svg';
import watchpatOneSrc from '../../images/sleep-study-product/watchpat-one.png';
import { useGetUserTimelineQuery } from '../../app/services/auth';

const SleepStudyUpload = () => {
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { refetch } = useGetMeQuery();

    const { refetch: refetchTimeline } = useGetUserTimelineQuery(user.id);

    const successCallback = async () => {
        await refetch();
        refetchTimeline();
        //delay for user experience
        setTimeout(() => {
            navigate('../study-in-review', { replace: true });
        }, 1000);
    };

    return (
        <div className="layout-outlet-root max-w-[1400px]">
            <div className="p-5 lg:p-0 max-w-5xl lg:mb-12">
                <h3 className="text-[44px] lg:text-6xl font-heading mb-4 leading-10">
                    Upload your sleep report
                </h3>
                <p className="text-sm lg:text-lg leading-normal mb-6">
                    No need to get a new test! Just upload the sleep study report/diagnosis from
                    your prior study. A GEM SLEEP provider will review and give you treatment
                    options.
                </p>

                <UploadFile
                    isModal={false}
                    fileType="UPLOADED_SLEEP_STUDY"
                    uploadSuccessCallback={successCallback}
                />

                <div className="flex flex-col lg:flex-row items-center justify-center lg:space-y-0 lg:space-x-4 lg:text-xl my-6 lg:my-10">
                    <button
                        className="font-heading text-green-400 tracking-wider hover:text-green-hover transition"
                        onClick={() => {
                            dispatch(toggleModal('problemsUploading'));
                        }}>
                        Having problems uploading?
                    </button>
                </div>

                <div className="grid grid-cols-[121px_1fr] gap-4">
                    <img
                        src={watchpatOneSrc}
                        className="bg-white max-w-[121px] sm:row-span-4"
                        alt="Sleep Test"
                    />
                    <h5 className="font-heading text-4xl lg:text-5xl">Need a new sleep study?</h5>
                    <p className="mb-1 text-[13px] leading-normal col-span-2 sm:col-span-1 lg:text-base">
                        If your previous sleep study does not meet the minimum requirements
                        (including containing a prescription and dated within the last 5 years), we
                        can help get you a new home sleep study through GEM SLEEP.
                    </p>
                    <p className="mb-1 text-[13px] leading-normal col-span-2 sm:col-span-1 lg:text-base">
                        Our home sleep study can be done with just one night of sleep -- no cords,
                        no wires, no lab -- the entire study is done completely at home.
                    </p>
                    <div className="items-center col-span-2 sm:col-span-1 space-y-4 lg:flex lg:space-y-0 lg:space-x-4">
                        <Link
                            to="/dashboard/diagnosis/gem-sleep-study"
                            className="btn-primary flex items-center justify-between space-x-4">
                            <span>GET AN AT-HOME SLEEP STUDY</span>
                            <TopRightArrow className="scale-150" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SleepStudyUpload;

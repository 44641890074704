import { api } from './auth';
const ONE_HOUR = 60 * 60;
// default cache time is 60 seconds, so we override it to 1 hour because this stuff
// will most likely never change during a user's session
const webflowAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        getAdviceQuotes: builder.query({
            query: () => '/webflow?collection=advice-quotes',
            keepUnusedDataFor: ONE_HOUR,
        }),
        getBlogCategories: builder.query({
            query: () => '/webflow?collection=blog-category',
            keepUnusedDataFor: ONE_HOUR,
        }),
        getBlogPosts: builder.query({
            query: () => '/webflow?collection=post',
            keepUnusedDataFor: ONE_HOUR,
        }),
        getWebflowTerms: builder.query({
            query: () => '/webflow?collection=terms',
            keepUnusedDataFor: ONE_HOUR,
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetAdviceQuotesQuery,
    useGetBlogCategoriesQuery,
    useGetBlogPostsQuery,
    useGetWebflowTermsQuery,
} = webflowAPI;
